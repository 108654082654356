<template>
    <div class="convertIntegral">
        <!--头部-->
        <nav-bar title="兑换礼券" url="/user" :border="border" :leftArrow="leftArrow"></nav-bar>
        <!--内容-->
        <div class="containerView-main">
            <div class="field-item f-bgf">
                <div class="field-tip">兑换编号：</div>
                <input class="field-input" v-model.trim="code" type="text" placeholder="请输入兑换编号" />
            </div>
            <div class="field-item f-bgf">
                <div class="field-tip">兑换密码：</div>
                <input class="field-input" v-model.trim="password" type="text" placeholder="请输入兑换密码" />
            </div>

            <div class="claim-integral">
                <div class="big-btn" @click="onSubmit">
                    立即兑换
                </div>
            </div>
            <div class="convert-exp">
                <h3>兑换说明：</h3>
                <p>一、该礼品卷兑换所得积分仅限兑换【礼包专区】</p>
                <p>二、兑换的礼品不可退换货</p>
                <p>四、余额不可折现，过期作废。</p>
                <p>五、按照下单顺序陆续发货</p>
                <p>最终解悉权归互联网点商标管理机构所有</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'convertIntegral',
    data() {
        return {
            border: true,
            leftArrow: true,
            code: '', // 卡号
            password: '', // 密码
        };
    },
    methods: {
        onSubmit() {
            let tips = null;
            if (!this.code) {
                tips = '请输入兑换编号';
            } else if (!this.password) {
                tips = '请输入兑换密码';
            }
            if (tips) {
                this.$toast({
                    message: tips,
                });
                return false;
            }
            this.$axios
                .post(`/v1/home/exchangeTicket?token=${sessionStorage.token}`, {
                    number: this.code,
                    password: this.password,
                })
                .then(res => {
                    let data = res.data.data;
                    if (data.code === 1000) {
                        this.$toast({
                            message: data.msg || '兑换成功',
                        });
                        sessionStorage.guid = '5';
                        setTimeout(() => {
                            this.$router.replace({
                                path: '/goodsList',
                                query: {
                                    title: '礼包专区',
                                },
                            });
                        }, 1500);
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.convertIntegral {
    padding-top: 1rem;
    .claim-integral {
        margin-top: 0.6rem;
        padding: 0 0.3rem;
    }
    .convert-exp {
        // background:#ffffff;
        padding: 0.3rem;
        border-top: 0.02rem solid #f1f1f1;
        p {
            font-size: 0.26rem;
            padding-top: 0.1rem;
        }
    }
}
</style>
